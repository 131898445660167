import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";

const UtilityPatentApplication = () => {
    const seo = {
        metaDesc: 'Utility Patent Application - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Utility Patent Application - Inventiv.org" canonical='/provisionalbuilder' seo={seo} />

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container">
                    <h1>1. Utility Patent Application</h1>
                    <p>While provisional patent applications (PPAs) can be filed with minimal expense, PPAs need to be converted to a utility patent application within a year for the invention to be protected as an issued patent.</p>
                    <p>You can file the utility application without a prior PPA filing. Alternatively, if you filed a PPA earlier, you can convert the PPA into a utility application by filing the utility application within one year of the PPA filing and claiming the priority date of the PPA.&nbsp; If properly claimed and the PPA has sufficient details as in the utility application, the effective date of the utility will be the filing date of the PPA even though the utility is filed months after the PPA.</p>
                    <div class="">
                        <p><strong>Table of Contents</strong></p>
                        <p><strong>1.1&nbsp; <a href="#utilitypatentapplication" class="d-clr">Preparatory steps before drafting your Utility Patent Application</a>?</strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.1&nbsp; <a href="#process" class="d-clr">Document the invention process.</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.2&nbsp; <a href="#search" class="d-clr">Search for prior art.</a></strong></p>
                        <p style={{ paddingLeft: 80 + 'px' }}><strong>1.1.2.2&nbsp;&nbsp;&nbsp; <a href="#diy" class="d-clr">DIY patent research</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.1.3&nbsp; <a href="#imperfections" class="d-clr">Be aware of imperfections in the search process</a></strong></p>
                        <p><strong>1.2&nbsp; <a href="#inventorship" class="d-clr">Inventorship – what happens when you have multiple inventors?</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.2.1&nbsp; <a href="#fix" class="d-clr">How to fix improper inventorship?</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.2.2&nbsp; <a href="#typos" class="d-clr">How to correct typos in the name?</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.2.3&nbsp; <a href="#ownership" class="d-clr">Ownership</a></strong></p>
                        <p><strong>1.3 &nbsp;<a href="#protect" class="d-clr">How to protect your ownership if you hire consultants to work on your invention?</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.3.1&nbsp; <a href="#consultant" class="d-clr">Consultant’s Agreement</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.3.2&nbsp; <a href="#joa" class="d-clr">Joint Ownership Agreement (JOA)</a></strong></p>
                        <p><strong>1.4 &nbsp;<a href="#employer" class="d-clr">Does your employer have rights in your invention?</a></strong></p>
                        <p style={{ paddingLeft: 40 + 'px' }}><strong>1.4.1&nbsp; <a href="#submission" class="d-clr">If your employer isn’t interested in your invention submission</a></strong></p>
                        <p><strong>&nbsp;</strong></p>
                    </div>
                    <div class="">
                        <h2 id="utilitypatentapplication">1.1 Preparatory steps before drafting your Utility Patent Application?</h2>
                        <p>&nbsp;</p>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        1. Evaluate the Commercial Potential of Your Invention.
                                        <p></p>
                                        <p>2.&nbsp; Document your invention in detail.</p>
                                        <p>3.&nbsp; Search for prior art to help you assess novelty and draft the application appropriately</p>
                                        <p>4.&nbsp; Perform a Thorough Patent Search.</p>
                                        <p>5.&nbsp; Identify inventorship.</p>
                                        <p>6.&nbsp; If joint invention, get a formal agreement on how ownership/control of the patent is exercised</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <img src="../assets/img/banner/4.jpg" width="100%" alt="" />
                    <div class="">
                        <h3>1.1.1 Document the invention process.</h3>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>This is done by recording your progress.</li>
                                            <li>Buy a bound notebook. Despite technological advances, the best way to document your invention remains hand-writing details regarding your idea, descriptions, and tests on paper.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <p>While you can use electronic records as inventor’s notebooks, the golden standard is still a bound notebook with consecutively numbered pages.&nbsp; You use this inventor’s notebook to detail when you envisioned your invention and when you started constructing it.</p>
                        <p>This is important because inventor’s notebooks, along with corroborating evidence, have been central in proving who was first to invent. Even with the first-to-file system, inventor notebooks are still important to show derivation.</p>
                        <p>A typical lab notebook will include such items as:</p>
                        <ul>
                            <li>A description of the invention;</li>
                            <li>Testing and results;</li>
                            <li>Prior art research; and</li>
                            <li>Drawings of the invention.</li>
                        </ul>
                        <p>After each entry, you make in the notebook, be sure to insert the date you made the entry and sign it.&nbsp; Entries should be chronological and made as soon as you complete each step of the invention process.</p>
                        <p>Separate print-outs, photos, and other documents should be permanently pasted into the notebook.&nbsp; You should also write a caption describing what the document is, along with dating and signing it.</p>
                        <p>Additionally, be sure to have a trusted witness sign each entry you make in the notebook.&nbsp; Again, this is helpful if someone brings a suit.&nbsp; Though you only need one witness, it’s preferable to have at least two just in case one is unavailable to testify later at trial if that’s necessary.&nbsp; The witness should be knowledgeable about the invention’s field because he/she must testify that he/she understands what’s written in the notebook.</p>
                        <p>It is recommended that witnesses also sign a confidentiality agreement prior to reading your notebook.&nbsp; This may help protect the trade secrets in your notebook as long as you meet the statutory requirements, which were discussed previously, to prove the notebook contains trade secrets.</p>
                        <p>In the Congressional Record dated March 3, 2011, Congress said inventors can file a provisional patent application to bypass some of the issues associated with the inventor’s notebook. This application has the same function as the inventor’s notebook because it requires an invention’s description, including what the invention is and how it works. It is also a dated government document, so one cannot easily tamper with it. Furthermore, this does away with expensive discovery if a claim is later challenged.</p>
                    </div>
                    <div class="">
                        <h3 id="search">1.1.2 Search for prior art.</h3>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>A prior art search involves searching for various publicly available sources to find out whether an invention has been previously described or detailed.</li>
                                            <li>If an invention has been described in a prior art reference, The application is consider not novel and would not be granted as anticipated by the prior art.</li>
                                            <li>The sources of prior art include patents, published patent applications, periodicals, books, and products.</li>
                                            <li>However, the most common prior art used by Examiners at the United States Patent and Trademark Office (USPTO) are previously granted patents and patent publications.</li>
                                            <li>While you can perform the prior art search yourself, you’ll likely still need a patent attorney to help you develop a patent strategy and analyze patentability.</li>
                                            <li>If you’re working in a technical field where you don’t have extensive experience, you should probably hire someone with experience for the search.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>A patent search can be beneficial at various stages of the inventing process. An initial patent search at the time of conception and formulation of an idea helps you assess the depth and extent others took in solving similar problems. Moreover, for brainstorming, the patent search can help refine your idea and stimulate further inventive thinking. Furthermore, a comprehensive awareness of the prior art can aid you when you draft claims.&nbsp; You’ll learn how to write claims with the appropriate scope so you can obtain patents that are as broad as possible, yet defensible.</p>
                        <p>To search for prior art, sometimes known as a patentability search, you can go directly to the USPTO office in Alexandria, the USPTO’s patent and trademark depository libraries nationwide, or visit online databases.</p>
                    </div>
                    <div class="">
                        <h5>1.1.2.1 Professional patent researcher</h5>
                        <p>If you don’t want to research yourself, you can hire professional patent researchers, such as a patent attorney or agent. One advantage of hiring a professional is that he/she understands the concept of novelty and obvious, so he/she may do more thorough research than a non-professional.</p>
                        <h5>1.1.2.1.1 What to give the researcher</h5>
                        <p>Before performing a patentability search, a researcher needs to know about your invention so if he/she is not a patent attorney or agent, have him/her sign a confidentiality agreement. If you’re working with a patent attorney or agent, this is not necessary because your conversations with him/her are already considered confidential under the law.<br /> Next, provide your researcher with a description of the invention, including what makes it novel, drawings, an idea of what classes to search, and applicable deadlines. If you provide your researcher with your inventor’s notebook, be sure to protect yourself by only providing a copy and redacting any dates in this copy of the notebook.</p>
                        <h5>1.1.2.1.2 What to expect from the researcher</h5>
                        <p>After the researcher is finished, he/she will usually tell you what they found, including what classes and subclasses he/she searched, and the patents and references he/she discovered. If you are working with a patent attorney or agent, he/she will also provide his/her opinion about the patentability of your invention.</p>
                    </div>

                    <div class="">
                        <h4>1.1.2.2 DIY patent research</h4>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>Keywords searches</li>
                                            <li>Class and subclass lookup</li>
                                            <li>Search patent and published application databases</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <img src="../assets/img/banner/search-for-solution_GyzJNDw_-e1602436304659.jpg" width="100%" alt="" />
                    <div class="">
                        <h5>1.1.2.2.1&nbsp; Start with the USPTO’s research system</h5>
                        <p><strong>&nbsp;</strong>It’s best to visit a USPTO research facility.&nbsp; The one in Alexandria, Virginia, is preferred because its computers have the Exam Assisted Searching Tool (EAST), and you can talk directly with examiners and other staffers who are there to assist researchers.&nbsp; EAST is a free tool, though you have to pay for printing.&nbsp; With EAST, you can search via class or subclass, Boolean or keyword terms, or a combination of both.</p>
                        <p>You can visit a Patent and Trademark Depository Library as well.&nbsp; These are located in major cities.&nbsp; The USPTO’s website lists where they are and their hours of operation.&nbsp; These do not have EAST but have the Web-based Examiner Search Tool (WEST).&nbsp; The libraries also do not have information related to foreign patents or non-patent materials, such as journals.</p>
                        <p>If those aren’t options, you can also start with an online or patent database search. The USPTO, European Patent Office, and Google, for example, offer this service for free, and there are other fee-based search tools. Though the records here may not go as far back as EAST, the databases are oftentimes easier to navigate.</p>
                        <h5>1.1.2.2.3 Narrow down your search terms</h5>
                        <p><strong><em>&nbsp;</em></strong>Become familiar enough with your patent application, including the abstract, specification, and claims, so you can come up with Boolean search terms and the classification that best describes the nature of your invention.&nbsp; These search terms need to be thorough and exact in order for you to find patents already in the USPTO’s system, so coming up with synonyms may be helpful.&nbsp; The USPTO website can also help you navigate the myriad of classifications.&nbsp; It can take some trial and error to figure out exactly what you’re searching for and how to find it.</p>
                        <h3 id="imperfections">1.1.3 Be aware of imperfections in the search process</h3>
                        <p>Though you may believe your search to be good, whether you do it yourself or hire a professional, it is probably not as in-depth as what a patent examiner will do.&nbsp; For example, that’s because:</p>
                        <ul>
                            <li>you won’t find pending applications that aren’t published,</li>
                            <li>there’s erroneous patent classification in the databases that can’t be discovered via keyword searches,</li>
                            <li>recently issued patents won’t be in the system immediately, or</li>
                            <li>S. patent searches won’t bring up foreign patents.</li>
                        </ul>
                    </div>
                    <div class="">
                        <h2 id="inventorship">1.2 Inventorship – what happens when you have multiple inventors?</h2>
                        <p>&nbsp;</p>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>Each inventor needs to sign the oath/declaration (Form PTO/AIA/01)</li>
                                            <li>Inventorship is decided based on contribution to an idea recited in at least one claim.</li>
                                            <li>Co-inventors should have an agreement specifying how the eventual patent is to be managed or controlled.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                        <p>Inventors include people who contribute to the conception and reduction to practice of the invention. Conception is the formation, in the mind of the inventor, of a definite and permanent idea of a complete and operative invention, while reduction to practice requires that the claimed invention work for its intended purpose. To qualify as co-inventor, each inventor must have contributed to an idea that became a part of at least one of the invention’s claims.&nbsp; To be considered an inventor, one must contribute to conception (i.e., the mental aspect of inventing). Those who only contribute to reduction to practice are not considered inventors, no matter how much time and energy they put into the project.</p>
                        <p>Many inventions arise through collaborations between two or more persons whose contributions arise to co-inventorship level.&nbsp; However, merely implementing the inventor’s instructions is insufficient contribution.&nbsp; For example, if a person were hired to implement an invention at the inventor’s direction, that person is simply viewed as hired hands for the inventor and would not qualify as a co-inventor.</p>
                    </div>
                    <div class="">
                        <h3 id="fix">1.2.1 How to fix improper inventorship?</h3>
                        <p>If after filing, you discover that you accidentally left out a co-inventor, or perhaps that you have listed a person who should not be a co-inventor, you can petition the PTO to correct the inventorship with supporting declarations from the other co-inventors as to how the inadvertent mistake had been made. The assignee also needs to agree to the proposed change. You will also need to pay a petition fee to correct inventorship, according to 37 CFR 1.324. &nbsp;The request includes:</p>
                        <ul>
                            <li>(1) Where one or more persons are being added, a statement from each person who is being added as an inventor that the inventorship error occurred without any deceptive intention on his or her part;</li>
                            <li>(2) A statement from the current named inventors who have not submitted a statement under paragraph (b)(1) of this section either agreeing to the change of inventorship or stating that they have no disagreement in regard to the requested change;</li>
                            <li>(3) A statement from all assignees of the parties submitting a statement under paragraphs (b)(1) and (b)(2) of this section agreeing to the change of inventorship in the patent, which statement must comply with the requirements of&nbsp;<a href="https://www.uspto.gov/web/offices/pac/mpep/mpep-9020-appx-r.html#aia_d0e336744"><strong> 3.73(b)</strong></a>of this chapter; and</li>
                            <li>(4) The fee set forth in&nbsp;<a href="https://www.uspto.gov/web/offices/pac/mpep/mpep-9020-appx-r.html#d0e316235"><strong> 1.20(b)</strong></a>.</li>
                        </ul>
                    </div>
                    <div class="">
                        <h3 id="typos">1.2.2 How to correct typos in the name?</h3>
                        <p>35&nbsp;U.S.C.&nbsp;256 Controls the&nbsp;Correction of named inventor.</p>
                        <ul>
                            <li>(a) CORRECTION.- Whenever through error a person is named in an issued patent as the inventor, or through error an inventor is not named in an issued patent, the Director may, on application of all the parties and assignees, with proof of the facts and such other requirements as may be imposed, issue a certificate correcting such error.</li>
                            <li>(b) PATENT VALID IF ERROR CORRECTED.- The error of omitting inventors or naming persons who are not inventors shall not invalidate the patent in which such error occurred if it can be corrected as provided in this section. The court before which such matter is called in question may order correction of the patent on notice and hearing of all parties concerned and the Director shall issue a certificate accordingly.</li>
                        </ul>
                    </div>
                    <div class="">
                        <h3 id="ownership">1.2.3 Ownership</h3>
                        <p>When you have co-inventors or joint inventors, all inventors share patent ownership.&nbsp; Each co-inventor “may make, use, offer to sell, or sell the patented invention within the United States, or import the patented invention into the United States, without the consent of and without accounting to the other owners.”&nbsp; Thus, like joint tenants of a house, any co-inventors can use, make, or sell the invention without permission from the others or paying them.&nbsp; Thus, the best way to control future conflicts is to have one voice in controlling the patent</p>
                    </div>
                    <div class="">
                        <h2 id="protect">1.3 How to protect your ownership if you hire consultants to work on your invention?</h2>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>Consultants who help the inventor should sign a consultant agreement to assign all the rights to the inventor.</li>
                                            <li>In case the co-inventors are part of a company, an assignment to the company should be done to perfect rights for the company.</li>
                                            <li>In the case of Joint Ownership among multiple inventors, an Agreement (JOA) should be signed among the inventors stating how the revenue should be shared and methods to resolve the dispute</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <img src="../assets/img/banner/IMG_2101-2156-e1602437204452.jpg" width="100%" alt="" />
                    <div class="">
                        <h3 id="consultant">1.3.1 Consultant’s Agreement</h3>
                        <p>If someone is just assisting the inventor, he/she should sign a Consultant’s Agreement. This agreement means the person assigns all of his/her rights to the invention to the inventor.</p>
                        <h3 id="joa">1.3.2 Joint Ownership Agreement (JOA)</h3>
                        <p>A JOA can prevent problems resulting from shared finances, such as who owns which financial shares to the invention. One common approach is to have all the co-inventors assign to a company. If there is no company to receive the assignment from all inventors, a JOA can be used. A JOA typically:<br /> • Prevents one inventor from exploiting the patent without the others’ consent. Though a majority of the inventors can act if all of them cannot reach a unanimous agreement;<br /> • Includes a method for resolving disputes;<br /> • Includes details about how to divide any revenue, which is usually proportional to expenses incurred by each inventor; and<br /> • Guides the course of action when one inventor wants to manufacture and sell the invention, including how to divide royalties.</p>
                    </div>
                    <div class="">
                        <h2 id="employer">1.4 Does your employer have rights in your invention?</h2>
                        <table class="table table-bordered mt-3">
                            <tbody>
                                <tr>
                                    <td width="623">
                                        <ul>
                                            <li>If you invented in the same product/service space that your employer is in, you should consult a lawyer to ascertain that you have rights to your invention.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>If you are hired to invent or to develop new products for your employer, you may be obligated to transfer your invention rights to your employer.&nbsp; We recommend you consult a lawyer when you are in this situation.&nbsp;&nbsp; Situations where this may occur may include the following:</p>
                        <ul>
                            <li>You signed an employment agreement prior to the invention. Sometimes this is known as a preinvention agreement. The agreement usually contains a provision in which you surrendered your invention rights. This can include inventions you create in your spare time or at home so read this carefully;</li>
                            <li>You were hired or employed specifically to invent. Under U.S. Supreme Court precedent, your employer doesn’t even need to have you sign a separate agreement for this;</li>
                            <li>Your employer acquired shop right, which is a nontransferable, limited patent right to use the invention for his/her use and business purposes only. To claim this, you must have used your employer’s resources, such as time, computer, equipment, or supplies, to create the invention; or</li>
                            <li>You work for a college or university, and you’ve granted the school rights to all inventions you make when you use its resources.</li>
                        </ul>
                    </div>
                    <div class="">
                        <h3 id="submission">1.4.1 If your employer isn’t interested in your invention submission</h3>
                        <p>If you have informed your employer about your invention and the company is not interested in it, you can ask for a release. A release is a document that has the employer return the invention back to you.</p>
                    </div>

                </div>
            </section>
        </Layout>
    )
};

export default UtilityPatentApplication;